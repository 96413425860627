import './App.css'
import { Canvas } from './Canvas'
import {useEffect, useState} from "react";
import {GAME_CONFIG} from "./game/constants";
import {Controls} from "./Controls";

function App() {
    const [speed,setSpeed] = useState(6)

    useEffect(()=>{
        GAME_CONFIG.speed = speed;
    }, [speed])

    return (
        <div className="App" style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: '100vw',
            height: '100vh'

        }}>
            <Canvas></Canvas>
            <Controls setSpeed={setSpeed} speed={speed}/>

        </div>
    )
}

export default App
