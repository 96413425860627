import {Vec2} from "./types";
import {GAME_CONFIG} from "./constants";
import {getCanvasSize, getCellState, getOppositeTeamColor, getTeamColor, setCellState} from "./helpers";


function getRandomSign(){
    return Math.random() > 0.5 ? 1 : -1
}

export class Ball{
    velocity: Vec2 = [1,1]
    hitCount = 0;

    constructor(public position: Vec2, public team: boolean) {
        const rand = Math.random()
        this.velocity[0] = rand * getRandomSign()
        this.velocity[1] = (1-rand) * getRandomSign()
    }

    getNextPos(): Vec2{
        return [this.position[0]+(this.velocity[0]* GAME_CONFIG.speed),this.position[1]+(this.velocity[1]* GAME_CONFIG.speed)]
    }

    processMovement(context: CanvasRenderingContext2D){
        const WIDTH = GAME_CONFIG.width
        const HEIGHT = GAME_CONFIG.height
        const sizePx = getCanvasSize(context)

        // Current X/Y coord of the ball in cell space
        const cellX = Math.floor(this.position[0] / (sizePx.widthPx/WIDTH));
        const cellY = Math.floor(this.position[1] / (sizePx.heightPx/HEIGHT));

        // next x/y coord of the ball in cell space
        const nextPost = this.getNextPos()
        const nextCellX = Math.floor(nextPost[0] / (sizePx.widthPx/WIDTH));
        const nextCellY = Math.floor(nextPost[1] / (sizePx.heightPx/HEIGHT));


        // If next step is border collision
        if(nextCellX > WIDTH-1) this.velocity[0] *= -1
        if(nextCellX <0) this.velocity[0] *= -1
        if(nextCellY > HEIGHT-1) this.velocity[1] *= -1
        if(nextCellY <0) this.velocity[1] *= -1


        // Check if cell is our team
        let nextState = getCellState(nextCellX,nextCellY)
        if(nextState != null && nextState !== this.team){
            // COLLISION
            setCellState(nextCellX,nextCellY, this.team)
            this.hitCount++;
            const yDiff = nextCellY - cellY
            const xDiff = nextCellX - cellX
            this.team ? GAME_CONFIG.hitCount1++ : GAME_CONFIG.hitCount0++

            // bounce from cell
            if(yDiff) this.velocity[1]*=-1
            if(xDiff) this.velocity[0]*=-1
        }




        // Update the balls position
        this.position[0] += this.velocity[0] * GAME_CONFIG.speed
        this.position[1] += this.velocity[1] * GAME_CONFIG.speed

    }

    draw(context: CanvasRenderingContext2D){
        const sizePx = getCanvasSize(context)
        const x = this.position[0]
        const y = this.position[1]

        const cellWidthPx = Math.floor(sizePx.widthPx/GAME_CONFIG.width)

        const radius = Math.floor(cellWidthPx/2);

        drawCircle(context,x,y,radius, this.team)
        // Draw center dot
        // drawCircle(context,x,y,1, '#000')

    }
}


function drawCircle(context: CanvasRenderingContext2D, x: number, y: number, radius: number, team: boolean){
    context.beginPath()
    context.fillStyle = getTeamColor(team)
    context.arc(x, y, radius, 0, 2 * Math.PI);
    context.strokeStyle = getOppositeTeamColor(team)
    context.fill()
    context.stroke()
    context.closePath()
}