import {Vec2} from "./types";
import {Ball} from "./Ball";

export const BALL_NUM = 1 // number of balls per team


export const COLOR_CELL_1 = '#323a69'
export const COLOR_CELL_2 = '#c3cbf3'

export const COLOR_CELL_HOVER = '#fff'

// export const COLOR_CELL_1 = '#693232'
// export const COLOR_CELL_2 = '#f3c3c3'

export const GAME_CONFIG:{
    speed: number,
    height: number,
    width: number,
    cellState: boolean[],
    mouseCell: Vec2 | undefined,
    balls: Ball[],
    cellWidthPx: number,
    cellHeightPx: number,
    hitCount0: number,
    hitCount1: number
} = {
    speed: 1,
    height: 40,
    width: 1,
    cellState: [],
    mouseCell: undefined,
    balls: [],
    cellWidthPx: 1,
    cellHeightPx: 1,
    hitCount0: 0,
    hitCount1: 0
}
