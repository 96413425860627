import React, { useEffect, useRef } from 'react'
import {init} from './game/main'
import { addNewBall} from "./game/helpers";
import {GAME_CONFIG} from "./game/constants";

export function Canvas() {
    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current as HTMLCanvasElement | null


        if (canvas) {
            const context = canvas.getContext('2d')


            // Set actual size in memory (scaled to account for extra pixel density).
            const scale = window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
            canvas.width = Math.floor(canvas.clientWidth * scale);
            canvas.height = Math.floor(canvas.clientHeight * scale);

            // Normalize coordinate system to use CSS pixels.
            context!.scale(scale, scale);

            init(context!)
        }
    }, [])

    function onMouseMove(ev: any){
        // console.log(ev)
        if(!canvasRef.current) return
        const canvas = canvasRef.current as HTMLCanvasElement
        const mousePos = getMousePos(canvas, ev)
        // console.log(mousePos)

        const cellWidthPx = canvas.clientWidth / GAME_CONFIG.width
        const cellHeightPx = canvas.clientHeight / GAME_CONFIG.height

        const cellX = Math.floor(mousePos.x/cellWidthPx)
        const cellY = Math.floor(mousePos.y/cellHeightPx)


        GAME_CONFIG.mouseCell = [cellX,cellY]
        // console.log(getCellState(cellX,cellY))
    }

    function onMouseLeave(){
        GAME_CONFIG.mouseCell = undefined
    }

    function onClick(ev: any){
        if(!GAME_CONFIG.mouseCell) return
        addNewBall(GAME_CONFIG.mouseCell[0],GAME_CONFIG.mouseCell[1], !ev.shiftKey)
        // Add new ball
    }

    function getMousePos(canvas: HTMLCanvasElement, evt: any) {
        var rect = canvas.getBoundingClientRect();
        return {
            x: evt.clientX - rect.left,
            y: evt.clientY - rect.top
        };
    }

    return (
        <canvas
            style={{
                height: '100%',
                width: '100%',
                backgroundColor: '#000',
                cursor: "none"
            }}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            // onMouseOut={}
            onClick={onClick}
            ref={canvasRef}
        ></canvas>
    )
}
