import {GAME_CONFIG, COLOR_CELL_HOVER} from './constants'
import {
    getCanvasSize, getCanvasSizeFromConfig,
    getCellIndexFromCoords,
    getCoordsFromCellIndex,
    getOppositeTeamColor,
    initCellState
} from "./helpers";
import {Ball} from "./Ball";

function drawCells(context: CanvasRenderingContext2D) {
    const canvasSize = getCanvasSize(context)

    const cellWidthPx = canvasSize.widthPx / GAME_CONFIG.width
    const cellHeightPx = canvasSize.heightPx / GAME_CONFIG.height

    context.beginPath()

    const cellState = GAME_CONFIG.cellState;
    for (var i = 0; i < cellState.length; i++) {
        const state = cellState[i]
        const color = getOppositeTeamColor(state)


        const pos = getCoordsFromCellIndex(i)

        // const fill = () => {
        //     context.fillRect(
        //         pos.x * cellWidthPx,
        //         pos.y * cellHeightPx,
        //         cellWidthPx+1,
        //         cellHeightPx+1
        //     )
        // }

        context.fillStyle = color
        context.fillRect(
            pos.x * cellWidthPx,
            pos.y * cellHeightPx,
            cellWidthPx+1,
            cellHeightPx+1
        )


        // Color the cell with the mouse
        if(GAME_CONFIG.mouseCell){
            const mouseCellIndex = getCellIndexFromCoords(...GAME_CONFIG.mouseCell)
            if(i === mouseCellIndex){
                context.fillStyle = COLOR_CELL_HOVER
                context.fillRect(
                    pos.x * cellWidthPx,
                    pos.y * cellHeightPx,
                    cellWidthPx+1,
                    cellHeightPx+1
                )
                // context.strokeStyle = '#000';
                // stroke()
            }
        }



        // console.log(pos)



        // Uncomment to stroke cells
        // context.strokeStyle = '#666';
        // context.strokeRect(
        //     pos.x * cellWidthPx,
        //     pos.y * cellHeightPx,
        //     cellWidthPx,
        //     cellHeightPx
        // )
    }

    context.closePath()

}


// Called once a frame
export function render(context: CanvasRenderingContext2D) {
    const canvas = context.canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Process Cells
    drawCells(context)

    const balls = GAME_CONFIG.balls

    // Process balls
    for(let i=0;i<balls.length;i++){
        const ball =balls[i]
        ball.processMovement(context)
        ball.draw(context)
        context.font = "bold 14px serif";
    }


    window.requestAnimationFrame(() => {
        render(context)
    })
}


/**
 * Initialize data states and calculate row/col amount
 * @param context
 */
export function init(context: CanvasRenderingContext2D){
    const canvSize = getCanvasSize(context);
    const aspect = canvSize.widthPx / canvSize.heightPx
    // Height is pre-set
    GAME_CONFIG.width = Math.round(GAME_CONFIG.height * aspect)
    GAME_CONFIG.cellState = new Array(GAME_CONFIG.width*GAME_CONFIG.height).fill(false)

    GAME_CONFIG.cellWidthPx = canvSize.widthPx/GAME_CONFIG.width
    GAME_CONFIG.cellHeightPx = canvSize.heightPx/GAME_CONFIG.height
    // console.log(GAME_CONFIG)
    // console.log(GAME_CONFIG.cellState)

    resetLevel()
    render(context)
}


export function resetLevel(){
    GAME_CONFIG.balls = []
    initCellState()
    addBall()
}

function addBall(){

    const size = getCanvasSizeFromConfig()
    const quarterW = size.widthPx/4;


    const yOffset = (size.heightPx / 6) * Math.random()
    const yOffset2 = (size.heightPx / 6) * Math.random()

    const balls = GAME_CONFIG.balls
    // add to team A
    balls.push(
        new Ball([quarterW,(size.heightPx/2)+yOffset], false),
    )
    // add to team B
    balls.push(
        new Ball([size.widthPx-quarterW,(size.heightPx/2)+yOffset2], true),
    )
}


