// Return the cell state given x and y coordinates
import {COLOR_CELL_1, COLOR_CELL_2, GAME_CONFIG} from "./constants";
import {Ball} from "./Ball";



export function getCellState(x: number, y: number) {
    if(x > GAME_CONFIG.width-1 || x < 0 || y > GAME_CONFIG.height-1 || y < 0){
        return null
    }
    return GAME_CONFIG.cellState[y * GAME_CONFIG.width + x]
}

export function setCellState(x: number, y: number, value: boolean) {
    GAME_CONFIG.cellState[y * GAME_CONFIG.width + x] = value
}

// export function pixelToCellCoords(x,y){
//
// }

export function initCellState() {
    // Split in half
    GAME_CONFIG.cellState.forEach((cell, index) => {
        const pos = getCoordsFromCellIndex(index)
        const val = pos.x >( GAME_CONFIG.width / 2) ? true : false
        setCellState(pos.x,pos.y, val)
    })
}

// Given a cell index, returns its X/Y coordinates on the game map
export function getCoordsFromCellIndex(index: number) {
    const y = Math.floor(index / GAME_CONFIG.width)
    const x = Math.floor(index % GAME_CONFIG.width)

    return { x, y }
}

export function getCellIndexFromCoords(x: number, y: number){
    return (y*GAME_CONFIG.width) + x
}

// return the canvas size in pixels
export function getCanvasSize(context: CanvasRenderingContext2D) {
    return {
        widthPx: context.canvas.clientWidth,
        heightPx: context.canvas.clientHeight,
    }
}

export function getCanvasSizeFromConfig(){
    return {
        widthPx: GAME_CONFIG.width * GAME_CONFIG.cellWidthPx,
        heightPx: GAME_CONFIG.height * GAME_CONFIG.cellHeightPx
    }
}


export function getTeamColor(team: boolean){
    return team ? COLOR_CELL_2 : COLOR_CELL_1;
}

export function getOppositeTeamColor(team: boolean){
    return team ? COLOR_CELL_1 : COLOR_CELL_2;
}

export function addNewBall(x: number, y:number, friendly = true){
    const cellState = getCellState(x,y);
    if (cellState === null) return

    const team = friendly ? cellState : !cellState
    GAME_CONFIG.balls.push(new Ball([(x*GAME_CONFIG.cellWidthPx)+GAME_CONFIG.cellWidthPx/2,(y*GAME_CONFIG.cellHeightPx)+GAME_CONFIG.cellHeightPx/2], team))
}

// export function getCellSize(context: CanvasRenderingContext2D){
//     getC
// }