import {resetLevel} from "./game/main";
import './Controls.css'
import {GAME_CONFIG, COLOR_CELL_1, COLOR_CELL_2} from "./game/constants";
import {useEffect, useState} from "react";

interface ControlsProps {
    setSpeed: any,
    speed: number
}

export function Controls( {
    setSpeed,
    speed
}: ControlsProps){
    const [hitCount, setHitCount] = useState<[number,number]>([0,0])

    useEffect(()=>{
        setInterval(()=>{
            setHitCount([GAME_CONFIG.hitCount0, GAME_CONFIG.hitCount1])
        }, 30)
    },[])

    function reset(){
        resetLevel()
    }

    return<>
        <div style={{
            position: "fixed",
            top: 20,
            left: 20,
            backgroundColor: '#3333',
            borderRadius: 4,
            padding: '1em',
        }} className='container'>
            <div className='scores'>
                <div>
                    <div className='team_circle' style={{backgroundColor: COLOR_CELL_1}}></div>
                    <p>{hitCount[0]}</p>
                </div>
                <div>
                    <p>{hitCount[1]}</p>
                    <div className='team_circle' style={{backgroundColor: COLOR_CELL_2}}></div>
                </div>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: 'center'
            }}>
                <label style={{
                    fontWeight: 'bold',
                    fontSize: '0.7em'
                }}>SPEED</label>
                <p style={{margin: 0}}>{speed}</p>
            </div>
            <input type='range' min={0.1} max='20' step='0.1' onInput={(val)=>{
                setSpeed(parseFloat(val.currentTarget.value))
            }} value={speed}
                style={{width: '100%'}}
            />
            <p style={{fontSize: '0.8em', marginBottom: '0'}}>Hold <b>SHIFT</b> to add balls of the opposite team</p>
            <button onClick={reset}>Reset</button>
            <div>

            </div>
        </div>
    </>
}